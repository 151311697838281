import * as React from "react";
import lottie from "lottie-web";

import scrollingWebsiteJSON from '../../../../assets/animations/lottie/scrollingWebsite.json'
// import scrollingWebsiteJSON from '../../../../assets/animations/lottie/typingLetter.json'

const HomeTopImageAnimation = () => {
    const lottieRef = React.useRef<HTMLDivElement>(null);
    const animationName = 'homePageTopAnimation'

    React.useEffect(() => {
        var animDuration = 400;
        const anim = lottie.loadAnimation({
          container: lottieRef.current!,
          renderer: "svg",
          loop: false,
          autoplay: false,
          animationData: scrollingWebsiteJSON,
          name: animationName
        });
    
        function animatebodymovin(duration: number) {
          const scrollPosition = window.scrollY;
          const maxFrames = anim.totalFrames;
    
          const frame = (maxFrames / 100) * (scrollPosition / (duration / 100));
          if (scrollPosition > 0 && frame <= maxFrames) {
            anim.goToAndStop(frame, true, animationName);
          }
        }
        const onScroll = () => {
          animatebodymovin(animDuration);
        };
    
        document.addEventListener("scroll", onScroll);
    
        return () => {
          anim.destroy(animationName);
          document.removeEventListener("scroll", onScroll);
        };
      }, []);
    
      return <div ref={lottieRef}></div>;
    };
  
  export default HomeTopImageAnimation;