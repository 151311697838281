import * as React from "react";
import tw from "twin.macro";

import ScrollToDisplayText from "./ScrollToDisplayText";

import { HomePageSubHeading } from "../../../../assets/theme/Headings";
const Heading2 = tw(HomePageSubHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight py-4 text-black tracking-wide text-center`

const FinishInTwoWeeksScrollRevealText = () => {
    const lottieRef = React.useRef<HTMLDivElement>(null);

    const text1 = "We'll get you online in दुई हप्ता."

    React.useEffect(() => {
        ScrollToDisplayText({
            target: lottieRef,
            text: text1,
            animationPositioning: "top center"
        })
      }, []);
      
      return (
          <div className="flex justify-center">
              <Heading2 ref={lottieRef}></Heading2>
          </div>
      )
    }
  
  export default FinishInTwoWeeksScrollRevealText;