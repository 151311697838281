import tw from "twin.macro";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";


import { Container, ContentWithPaddingXl } from "../../../assets/theme/Layouts";
import { SectionHeading, SectionSubheading } from "../../../assets/theme/Headings";
import { SectionDescription } from "../../../assets/theme/Typography";

import FAQ from "../../../Models/FAQ";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-white text-gray-100`;

const HeadingContainer = tw.div``;
const Header = tw(SectionHeading)``;
const SubHeader = tw(SectionSubheading)`text-center text-primary-800 mb-4`;
const Description = tw(SectionDescription)`mx-auto text-center text-primary-600`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;

interface IProps {
    heading: string
    subheading?: string
    description?: string
    faqs: FAQ[]
}

export default (props: IProps) => {
    let faqCol1: JSX.Element[] = [];
    let faqCol2: JSX.Element[] = [];

    props.faqs.map((faq, index) => {
        const currFAQ = (
            <Disclosure as="div" className="mt-2">
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-purple-900 bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                            <span>{faq.question}</span>
                            <ChevronUpIcon
                                className={`${open ? 'transform rotate-180' : ''
                                    } w-5 h-5 text-purple-500`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            {faq.answer}
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        )

        if (index % 2 === 0) faqCol1.push(currFAQ);
        else faqCol2.push(currFAQ);

        return null;
    });
    return (
        <PrimaryBackgroundContainer>
            <ContentWithPaddingXl>
                <HeadingContainer>
                    {props.subheading && <SubHeader>{props.subheading}</SubHeader>}
                    <Header>{props.heading}</Header>
                    <Description>{props.description}</Description>
                </HeadingContainer>
                <FaqsContainer>
                    <FaqsColumn>{faqCol1}</FaqsColumn>
                    <FaqsColumn>{faqCol2}</FaqsColumn>
                </FaqsContainer>
            </ContentWithPaddingXl>
        </PrimaryBackgroundContainer>
    );
};

export const defaultFaqs: IProps = {
    subheading: "",
    heading: "Frequently Asked Questions",
    description: "Yes, we are based in Nepal",
    faqs: [
        {
            question: "What kind of SLA Guarantee do you provide ? ",
            answer:
                "We guarantee that your website will not be down for more than 8 hours in a day, and less than 10 days in a year"
        },
        {
            question: "Will my website be really up and running in two weeks ?",
            answer:
                "Yes. The only thing that will remain after two weeks is the content you want to put online."
        },
        {
            question: "Do you use Wordpress or other blog creating websites ?",
            answer:
                "No, we would like your online presence to grow and be customized as your business grows, and so we use the latest technology in web application development."
        },
        {
            question: "Can I update my website by myself ?",
            answer:
                "Yes and No. You will be able to add, change, and remove any content in your website, but the overall design of the website will always constant",
        },
        {
            question: "I'd love to work together. Can we meet in person ?",
            answer:
                "Yes, we'd love to. Contact us from our Contact Us page and we can provide information about what can be arranged"
        }
    ]
}