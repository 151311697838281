import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SectionSubheading as SubheadingBase } from "../../../assets/theme/Headings";
import { SectionDescription } from "../../../assets/theme/Typography";
import { PrimaryButton as PrimaryButtonBase } from "../../../assets/theme/Buttons";
import { Container, ContentWithPaddingXl } from "../../../assets/theme/Layouts";
import { ReactComponent as SvgDecoratorBlob1 } from "../../../assets/Treact/images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../../assets/Treact/images/svg-decorator-blob-7.svg";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${props =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-4xl sm:text-5xl`}
  }
  .price {
    ${tw`text-gray-500 font-medium tracking-widest my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide pt-8`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const ContactUsA = tw.a`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`
const ContactUsButton = tw(ContactUsA)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({
  subheading = "Pricing",
  heading = "Flexible Plans.",
  description = "You pay only after you receive and are satisfied.",
  plans = null,
  primaryButtonText = "Contact Us",
  websiteType = [
    {
      switcherText: "Website",
    },
    {
      switcherText: "App",
    }
  ]
}) => {
  const simplePlans = [
    {
      name: "Simple",
      price: "Nrs. 8,500",
      mainFeature: "For Giving Your Information",
      features: [
        "Choose from 100s of templates", 
        "Max 10 internal pages ('About Us', 'Contact us', 'Home') [Think a site like this]", 
        "Admin page to update content of internal pages", 
        "Admin dashboard to see website traffic", 
        "Customer Service: Basic Assistance", 
        "500 Mb Space free"
      ]
    },
    {
      name: "Active",
      price: "Nrs. 30,000",
      mainFeature: "For Information website with Blogs and Events",
      features: [
        "Includes all from 'Simple' plan", 
        "Create new Blogs, Press Releases, Events on a daily basis with media",
        "Customer Service: Priority Assistance", 
        "1 Gb Space free"
      ]
    },
    {
      name: "Super",
      price: "Nrs. 40,000",
      mainFeature: "For Active Website Management",
      features: [
        "Includes all from 'Active' plan'", 
        "Supports Multiple Users: Employee Login", 
        "More information in Admin Dashboard",
        "40 internal pages max",
      ]
    },
  ]

  const customizedPlans = [
    {
      name: "Webapp",
      price: "Nrs. 4,00,000+",
      mainFeature: "For Form Based Web Applications",
      features: [
        "Multiple User Management: Employees & Users", 
        "Admin page and Dashboard: Website usage data",
        "20 internal pages max", 
        "Customer Service: Priority Assistance", 
        "1 Gb space free",
        "Data Privacy Protection from Cyber attacks",
        "GDPR Compliant",
      ]
    },
    {
      name: "Web + iOS app",
      price: "Nrs. 7,00,000+",
      mainFeature: "Form Based Web and Native iOS app",
      features: [
        "All features from 'Form Based Webapp'", 
        "iOS app"
      ]
    },
  ]

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);
  if (activeDurationIndex == 0) {plans = simplePlans} else {plans = customizedPlans}

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        <PlanDurationSwitcher>
          {websiteType.map((planDuration, index) => (
            <SwitchButton active={activeDurationIndex === index} key={index} onClick={() => setActiveDurationIndex(index)}>{planDuration.switcherText}</SwitchButton>
          ))}
        </PlanDurationSwitcher>
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="priceAndDuration">
                  <span className="price">{plan.price}</span>
                </span>
                <span className="name">{plan.name}</span>
                <span className="mainFeature">{plan.mainFeature}</span>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <ContactUsButton href='/contactus'>{primaryButtonText}</ContactUsButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
