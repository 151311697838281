import * as React from "react";

import ScrollLottie, { ScrollSpeed } from "./ScrollLottie";

import scrollingWebsiteJSON from '../../../../assets/animations/lottie/fallingTwoLeaves.json'

const LeavesFallingAnimation = () => {
    const lottieRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        ScrollLottie({
            target: lottieRef, 
            animationData: scrollingWebsiteJSON,
            duration: 0.9,
            speed: ScrollSpeed.medium,
            start: "top center"
        })
      }, []);
    
      return <div ref={lottieRef}></div>;
    };
  
  export default LeavesFallingAnimation;