import React, { Component } from 'react'

class ProductListItem extends Component<{ name: string, price: string, imgsrc: string, imgalt: string }, {}> {
    render() {
      return (
        <a href="#" className="group">
          <div className="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
            <img src={this.props.imgsrc} alt={this.props.imgalt} className="w-full h-full object-center object-cover group-hover:opacity-75" />
          </div>
          <h3 className="mt-4 text-sm text-gray-700">
            {this.props.name}
          </h3>
          <p className="mt-1 text-lg font-medium text-gray-900">
            {this.props.price}
          </p>
        </a>
      )
    }
  }

export default ProductListItem