import React, { Component } from 'react'
import tw from 'twin.macro';
import FeaturesAndServices, { defaultFeaturesAndServices } from '../Components/Info/FeaturesAndServices';
import DonateForBeer from '../Components/Info/DonateForBeer';
// import Testimonials from '../Components/Info/Testimonials';
// import PricingList from '../Components/Info/PricingList';

import AnimationRevealPage from '../helpers/Treact/AnimationRevealPage';

import WebsiteHeader from '../Components/Info/WebsiteHeader';
import Navbar from '../Components/General/Navbar';

import FAQ, {defaultFaqs} from '../Components/Info/faqs/TwoColumnDisclosuresSecondaryBg';
import ShowPricing from '../Components/Info/ShowPricingButton';
import Footer from '../Components/Info/Footer';

import HomeTopRightScrollAnimation from '../Components/Animation/Pages/Home/ScrollAnimationUsingScrollLottie';

class Home extends Component {
    TopRightScrollAnimation = tw(HomeTopRightScrollAnimation)`w-80 h-80 p-8`

    render() {
        return (
            <div>
                <Navbar />
                <WebsiteHeader />
                <AnimationRevealPage>
                    <FeaturesAndServices {...defaultFeaturesAndServices} />
                    <this.TopRightScrollAnimation />
                    {/* <Testimonials /> */}
                    {/* <PricingList /> */}
                    <FAQ {...defaultFaqs}/>
                    <ShowPricing />
                </AnimationRevealPage>
                <DonateForBeer />
                <Footer />
            </div>
        )
    }
}

export default Home