import React from "react";
import tw from "twin.macro";

import { Container as ContainerBase, ContentWithTopPadding, ContentHeroContainerHolder } from "../../assets/theme/Layouts";
import { HomePageHeading } from "../../assets/theme/Headings";
import serverIllustrationImageSrc from "../../assets/Treact/images/server-illustration-2.svg";
import HomeTopImageAnimation from "../Animation/Pages/Home/HomeTopImageAnimation";

import FinishInTwoWeeksScrollRevealText from "../Animation/Pages/Home/FinishInTwoWeeksScrollRevealText";
import LeavesFallingAnimation from "../Animation/Pages/Home/LeavesFallingAnimation";

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primaryBg text-gray-100`;

const Container = tw(ContainerBase)`min-h-screen md:min-h-screen-3/4 border-0 border-blue-900`;
const HeroRow = tw.div`flex items-center flex-col sm:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw(Column)`p-8 text-center lg:text-left lg:ml-16`;
const Heading = tw(HomePageHeading)`max-w-3xl lg:max-w-4xl md:text-left leading-tight py-4`;
const IllustrationColumn = tw(Column)``;

const HalfContainer = tw(ContainerBase)`min-h-screen-1/2 md:min-h-screen-3/4 border-0 border-green-900`;
const RevelStatementRow = tw.div`flex sm:items-center flex-col sm:flex-row mt-24 sm:mt-0`;
const RevealingStatementColumn = tw.div`my-0 lg:ml-16 w-full md:w-4/5 border-0 border-blue-900`;
const FallingLeavesColumn = tw.div`my-0 lg:ml-16 hidden md:block md:w-1/5 border-0 border-red-900`;

const StyledLeavesFallingAnimation = tw(LeavesFallingAnimation)`h-screen sm:h-screen`
const Image = tw.img`w-144 ml-auto`

export default ({
  heading = "तपाइँको संगठनको website बनाउन चाहनुहुन्छ ?",
  imageSrc = serverIllustrationImageSrc,
}) => {
  const websiteStatementRef = React.useRef<HTMLDivElement>(null);

  const executeScroll = () => {
    if (websiteStatementRef && websiteStatementRef.current /* + other conditions */) {
      websiteStatementRef.current.scrollIntoView({ behavior: 'auto', block: 'center' })
    }
  }

  return (
    <PrimaryBackgroundContainer>
      <ContentHeroContainerHolder>
        <Container>
          <ContentWithTopPadding>
            <HeroRow>
              <IllustrationColumn>
                <HomeTopImageAnimation />
              </IllustrationColumn>
              <TextColumn>
                <Heading>{heading}</Heading>
                <button className="primary-button mt-6 md:mt-4 hidden md:block" onClick={executeScroll}>Tell Me More</button>
              </TextColumn>
            </HeroRow>
          </ContentWithTopPadding>
        </Container>

        <HalfContainer>
          <RevelStatementRow>
            <FallingLeavesColumn>
              <StyledLeavesFallingAnimation />
            </FallingLeavesColumn>
            <RevealingStatementColumn ref={websiteStatementRef}>
              <FinishInTwoWeeksScrollRevealText />
            </RevealingStatementColumn >
          </RevelStatementRow>
        </HalfContainer>
        
      </ContentHeroContainerHolder>

    </PrimaryBackgroundContainer>
  );
};
