import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)

// props:
// - target: React.RefObject<HTMLDivElement>
const ScrollToDisplayText = (props) => {
  const quote = props.target.current

  var i = 0;
  var speed = 50;

  function typeWriter() {
    if (i < props.text.length) {
      quote.innerHTML += props.text.charAt(i);
      i++;
      setTimeout(typeWriter, speed);
    }
  }

  ScrollTrigger.create({
    trigger: quote,
    start: props.animationPositioning,
    once: true,
    onEnter: self => {
      typeWriter()
    },
  });
}

export default ScrollToDisplayText