import { Component } from 'react';
import TwoPlansWithDurationSwitcher from '../Components/Treact/pricing/TwoPlansWithDurationSwitcher';
import AnimationRevealPage from '../helpers/Treact/AnimationRevealPage';
import Testimonial from '../Components/Treact/testimonials/ThreeColumnWithProfileImage'
import FAQ from "../Components/Info/faqs/SingleCol.js";
import Navbar from '../Components/General/Navbar';
import Footer from '../Components/Info/Footer';

class PricingPage extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <AnimationRevealPage>
                    <TwoPlansWithDurationSwitcher />
                    <Testimonial heading="Our Paying Customers"/>
                    <FAQ />
                </AnimationRevealPage>
                <Footer />
            </div>
        )
    }
}

export default PricingPage