import { Component } from 'react';
import Navbar from '../Components/General/Navbar';
import Footer from '../Components/Info/Footer';
import PortfolioWorks, { defaultPortfolioWorks } from '../Components/Portfolio/PortfolioWorks';

class Portfolio extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <PortfolioWorks {...defaultPortfolioWorks} />
                <Footer />
            </div>
        )
    }
}

export default Portfolio