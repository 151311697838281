import React, { Component } from 'react'
import tw from 'twin.macro';

import FeatureAndService from '../../Models/FeatureAndService';
import { SectionHeading, SectionSubheading } from "../../assets/theme/Headings"

const SectionHeader = tw(SectionHeading)`sm:p-4`
const SectionSubHeader = tw(SectionSubheading)``

interface IProps {
    sectionHeader: string
    sectionSubHeader: string
    featuresAndServices: FeatureAndService[]
}

class FeaturesAndServices extends Component<IProps, {}> {
    featureAndServiceBox = (feature: FeatureAndService): JSX.Element => {
        return (
            <div className="mt-16 py-4 px-4 w-72 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-110 transition duration-500 mx-auto md:mx-0">
                <div className="w-sm">
                    <img className="w-64" src={feature.image} alt={feature.imageAltText} />
                    <div className="mt-4 text-green-600 text-center">
                        <h1 className="text-xl font-bold">{feature.title}</h1>
                        <p className="mt-4 text-gray-600">{feature.description}</p>
                    </div>
                </div>
            </div>
        )
    }

    featuresAndServicesBoxes = (): JSX.Element => {
        return (
            <div className="flex flex-wrap flex-col md:flex-row md:justify-center sm:space-x-12 sm:px-14">
                {this.props.featuresAndServices.map((feature) => (
                    this.featureAndServiceBox(feature)
                ))}
            </div>
        )
    }
    render() {
        // this.props.featuresAndServices
        return (
            <div className="bg-white-900 py-4">
                <SectionHeader>{this.props.sectionHeader}</SectionHeader>
                <SectionSubHeader>{this.props.sectionSubHeader}</SectionSubHeader>
                {this.featuresAndServicesBoxes()}
            </div>
        )
    }
}

export default FeaturesAndServices

export const defaultFeaturesAndServices: IProps = {
    sectionHeader: "Our Features & Services",
    sectionSubHeader: "Nothing less than the best in class in all of Kathmandu",
    featuresAndServices: [
        {
            title: "Responsive To Every Screen and Need",
            image: "https://images01.nicepage.com/c461c07a441a5d220e8feb1a/a17abde8d83650a582a28432/users-with-speech-bubbles-vector_53876-82250.jpg",
            imageAltText: "Image for responsive website design principle",
            description: "Your website will render beautifully in any and all device: laptop, phones, or even ebook reader",
            ctaButtonTitle: "More"
        },
        {
            title: "Effective Design",
            image: "https://images01.nicepage.com/c461c07a441a5d220e8feb1a/3b242447f922540fbe750cab/fdf.jpg",
            imageAltText: "Image for effective design principle",
            description: "We believe your website should tell your story, so our expert designers will ensure your content get the highest focus when users come to your website",
            ctaButtonTitle: "More"
        },
        {
            title: "Customer Service",
            image: "https://images01.nicepage.com/c461c07a441a5d220e8feb1a/8cc47b39e719570b996d9879/dsds.jpg",
            imageAltText: "Image for alert customer service principle",
            description: "Our platoon of customer service operators are always alert whenever you need help. You queries will be answered in less than 8 hours. We are always here for you.",
            ctaButtonTitle: "More"
        },
    ]
}