import './assets/css/App.css';

import Routes from './Routes/Routes';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAnalytics } from "firebase/analytics"
import { getPerformance } from "firebase/performance";

function App() {
  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyD_MXG6Hvq_ZJzkKsVjrCs_V3xLA9bqldo",
    authDomain: "samman-moaw.firebaseapp.com",
    projectId: "samman-moaw",
    storageBucket: "samman-moaw.appspot.com",
    messagingSenderId: "1033662800825",
    appId: "1:1033662800825:web:b76ef1901cd28a42bc4c68"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // For analytics
  // const analytics = getAnalytics(app);
  // Initialize Performance Monitoring and get a reference to the service
  // const perf = getPerformance(app);

  return (
    <div>
        <Routes />
     </div>
  );
}

export default App;
