import tw from "twin.macro";

// MAKE SURE I DO NOT PROVIDE Padding or Margin values here

// Single use in the Hero of home page
export const HomePageHeading = tw.p`text-4xl sm:text-3xl md:text-6xl font-black text-primary-900 tracking-wide text-center`
export const HomePageSubHeading = tw.h5`text-3xl sm:text-3xl md:text-5xl font-bold text-secondary-500`

// to be used by all section headers in a page
export const SectionHeading = tw.h2`text-4xl sm:text-4xl md:text-6xl font-bold text-primary-900 tracking-wide text-center`
export const SectionSubheading = tw.h5`text-xl sm:text-xl font-semibold text-secondary-500`