import PortfolioWork from "../../Models/PortfolioWork";
import { Component } from 'react'
import tw from "twin.macro";
import styled from "styled-components";
import { ExternalLinkIcon } from "@heroicons/react/outline";

import tsquarecafe from '../../assets/images/Portfolio/tsquarecafe.png'
import wuffett from '../../assets/images/Portfolio/wuffett.png'
import breastfeedingngo from '../../assets/images/Portfolio/breastfeedingngo.png'

import { SectionHeading, SectionSubheading } from "../../assets/theme/Headings"
const SectionHeader = tw(SectionHeading)`sm:p-4`
const SectionSubHeader = tw(SectionSubheading)``

interface IProps {
  sectionHeader: string
  sectionSubHeader: string
  portfolioWorks: PortfolioWork[]
}

interface StyledImageProps {
  imageSrc: string;
  imageContain: boolean;
  imageShadow: boolean;
}
const Image = styled.div<StyledImageProps>(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`block rounded-xl shadow-md bg-center`,

]);

class PortfolioWorks extends Component<IProps, {}> {
  portfolioWork = (work: PortfolioWork): JSX.Element => {
    const tags = work.tags.map((tag) => (
      <span className="tag m-2">#{tag}</span>
    ))
    return (
      <a href={work.linkToSite} target="_blank" className="h-screen-1/2 sm:h-96 w-full sm:w-screen-1/2 md:w-screen-1/4">
        <Image
          imageSrc={work.image}
          imageContain={true}
          imageShadow={false}
          className="group hover:shadow-xl transform hover:scale-110 transition duration-500 h-full w-full"
        >
          <div className="w-1/12 opacity-0 group-hover:opacity-100 backdrop-filter backdrop-blur-lg">
            <ExternalLinkIcon className="" />
          </div>

          <div className="mt-4 text-green-600 text-center absolute inset-x-0 bottom-0 opacity-0 group-hover:opacity-100">
            <div className="m-2">
              {tags.length > 0 && tags.map((tag) => (
                tag
              ))}
            </div>
          </div>
        </Image>
      </a>
    )
  }

  portfolioWorks = (): JSX.Element => {
    return (
      <div className="flex flex-wrap items-stretch items-center place-content-evenly flex-col md:flex-row justify-center md:justify-center space-y-8 md:space-x-8 m-8 md:px-14 sm:space-x-4 lg:space-x-12">
        {this.props.portfolioWorks.map((work) => (
          this.portfolioWork(work)
        ))}
      </div>
    )
  }
  render() {
    return (
      <div className="bg-white-900 py-4">
        <SectionHeader>{this.props.sectionHeader}</SectionHeader>
        <SectionSubHeader>{this.props.sectionSubHeader}</SectionSubHeader>
        {this.portfolioWorks()}
      </div>
    )
  }
}

export default PortfolioWorks

export const defaultPortfolioWorks: IProps = {
  sectionHeader: "Our Recent Website Works",
  sectionSubHeader: "",
  portfolioWorks: [
    {
      title: "Responsive To Every Screen and Need",
      image: tsquarecafe,
      imageAltText: "Image for t square restaurant website",
      tags: ["coffee shop", "brick and mortar"],
      linkToSite: "https://tsquarecafe.web.app"
    },
    {
      title: "Effective Design",
      image: wuffett,
      imageAltText: "Image for wuffett landing page website",
      tags: ["stock", "ios"],
      linkToSite: "https://buffett-landing-page.web.app"
    },
    {
      title: "Customer Service",
      image: breastfeedingngo,
      imageAltText: "Image for breastfeeding ngo website",
      tags: ["ngo"],
      linkToSite: "https://breastfeedingnepalngo.herokuapp.com"
    },
  ]
}