import { Switch, Route } from "react-router-dom"

// import the Home.tsx component
import Home from "../Pages/Home"
import Error404 from "../Pages/Error404"
import Sandbox from "../Pages/Sandbox"
import Login from "../Pages/Auth/Login"
import ContactUs from "../Pages/ContactUs"
import PricingPage from "../Pages/Pricing"
import Portfolio from "../Pages/Portfolio"

export default function Routes (){
   return(
     <Switch>
        <Route path="/" exact component={Home}></Route>
        <Route path="/login" exact component={Login}></Route>
        <Route path="/pricing" exact component={PricingPage}></Route>
        <Route path="/contactus" exact component={ContactUs}></Route>
        <Route path="/portfolio" exact component={Portfolio}></Route>
        <Route path="/error" exact component={Error404}></Route>
        <Route path="/sandbox" exact component={Sandbox}></Route>
     </Switch>
  )
}