import React from "react";
import lottie from "lottie-web";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { start } from "repl";

gsap.registerPlugin(ScrollTrigger)

export enum ScrollSpeed {
    fast,
    medium,
    slow
}

interface IProp {
    target: React.RefObject<HTMLDivElement>, 
    animationData: any, 
    duration: number, 
    speed: ScrollSpeed,
    start: string // // start when the bottom of the element hits the bottom of the screen
}

const ScrollLottie = (props: IProp) => {
    let anim = lottie.loadAnimation({
     container: props.target.current!, 
     renderer: 'svg',
     loop: false,
     autoplay: false,
     animationData: props.animationData
   });
   
   let timeObj = {currentFrame: 0}
   let endString = (props.speed === ScrollSpeed.slow) ? "+=2000" : (props.speed === ScrollSpeed.medium) ? "+=1000" : (props.speed === undefined) ? "+=1250" : "+=200";
   ScrollTrigger.create({
     trigger: props.target.current!,
       scrub: true,
       pin: false,
       start: props.start,
       end: endString, 
       onUpdate: self => {
        if(props.duration) {
         gsap.to(timeObj, {
          duration: props.duration,
          currentFrame:(Math.floor(self.progress *  (anim.totalFrames - 1))),
          onUpdate: () => {
           anim.goToAndStop(timeObj.currentFrame, true)
          },
          ease: 'expo'
         })
        } else {
          anim.goToAndStop(self.progress *  (anim.totalFrames - 1), true)
        }
       }
   });  
}

export default ScrollLottie