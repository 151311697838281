import React, { Component } from 'react'

import ProductListItem from '../Components/Product/productListItem';
import ProduceListCardItem from '../Components/Product/productListCardItem';
import ProductDetailOverview from '../Components/Product/productDetailOverview';

class Sandbox extends Component {
    render() {
        return (
            <div className="App">
                <div className="bg-white">
                    <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                        <div className="bg-blue-900 text-center py-4 lg:px-4">
                            <div className="p-2 bg-blue-800 items-center text-blue-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                <span className="flex rounded-full bg-blue-500 uppercase font-bold px-2 py-1 text-xs mr-3">New</span>
                                <span className="font-semibold mr-2 text-left flex-auto">Use Tailwind CSS to implement your own unique design!</span>
                            </div>
                        </div>
                        <h2 className="sr-only">Products</h2>

                        <button className="primary-button">My Tailwind Button</button>

                        <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                            <ProductListItem name="Focus Paper Refill" price="$89" imgsrc="https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-03.jpg" imgalt="Person using a pen to cross a task off a productivity paper card." />
                            <ProductListItem name="Machined Mechanical Pencil" price="$35" imgsrc="https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-04.jpg" imgalt="Hand holding black machined steel mechanical pencil with brass tip and top." />
                            <ProduceListCardItem />
                            <ProductListItem name="Nomad Tumbler" price="$89" imgsrc="https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-02.jpg" imgalt="Person using a pen to cross a task off a productivity paper card." />
                            <ProductListItem name="Machined Mechanical Pencil" price="$35" imgsrc="https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-04.jpg" imgalt="Hand holding black machined steel mechanical pencil with brass tip and top." />
                        </div>

                        <ProductDetailOverview />
                    </div>
                </div>
            </div>
        )
    }
}

export default Sandbox