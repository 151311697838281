import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Navbar from "../Components/General/Navbar";
import ContactUsForm from "../Components/Treact/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "../Components/Treact/cards/ThreeColContactDetails.js";
import AnimationRevealPage from "../helpers/Treact/AnimationRevealPage.js";
import DonateForBeer from "../Components/Info/DonateForBeer";
import Footer from "../Components/Info/Footer";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <div>
      <Navbar />
      <AnimationRevealPage>
        <ContactUsForm />
        {/*<ContactDetails
          cards={[
            {
              title: "Kathmandu",
              description: (
                <>
                  <Address>
                    <AddressLine>Chandol</AddressLine>
                    <AddressLine>Bishalnagar, Ward-4</AddressLine>
                  </Address>
                  <Email>sammanthp007@gmail.com</Email>
                  <Phone>+1 (203) 991-6988</Phone>
                </>
              )
            },
          ]}
        />*/}
      </AnimationRevealPage>
      <DonateForBeer />
      <Footer />
    </div>
  );
};
