/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { RouteComponentProps, withRouter } from "react-router"
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

import logo1080 from '../../assets/images/logo1080.jpeg'

interface NavigationInfo {
  name: string
  id: string
  link: string
  isCurrent: boolean
}
const navigation: NavigationInfo[] = [
  // { name: 'Dashboard', href: '#', current: true },
  // { name: 'About Us', href: '#', current: false },
  { name: 'Pricing', id: 'pricing', link: '/pricing', isCurrent: false },
  { name: 'Contact Us', id: 'contactus', link: '/contactus', isCurrent: false },
  { name: 'Portfolio', id: 'portfolio', link: '/portfolio', isCurrent: false },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface NavbarProps extends RouteComponentProps<any> {

}

function Navbar(props: NavbarProps) {
  return (
    <Disclosure as="nav" className="bg-white w-screen -mt-8 py-4 -mx-8 px-8">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-20">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch justify-center sm:justify-start">
                {/* Logos */}
                <a className="flex-shrink-0 flex items-center" href="/">
                  <img
                    className="block lg:hidden h-12 w-auto transform hover:scale-125"
                    src={logo1080}
                    alt="logo"
                  />
                  <img
                    className="hidden lg:block h-12 w-auto transform hover:scale-125"
                    src={logo1080}
                    alt="logo"
                  />
                </a>

                {/* Navigation links */}
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.link}
                        className={classNames(
                          item.isCurrent ? 'bg-gray-900 text-white' : 'text-gray-800 hover:text-black hover:underline',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.isCurrent ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              {/* Notification and profile image buttons. Hidden for now */}
              <div className="hidden absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Notification button. TODO: Set a conditional to true to show it */}
                {false &&
                  <button
                    type="button"
                    className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                }

                {/* Profile dropdown. TODO: Set as conditional to show */}
                {false &&
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Your Profile
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Settings
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                }
              </div>
            </div>
          </div>

          {/* The menu items in mobile */}
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  id={item.id}
                  key={item.id}
                  to={item.link}
                  className={classNames(
                    item.isCurrent ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.isCurrent ? 'page' : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default withRouter(Navbar)