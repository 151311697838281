import React, { Component } from 'react';

class ProduceListCardItem extends Component {
    render() {
      return (
        <div className="pt-5">
          <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white">
            <img src="https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-04.jpg" alt="Tailwind CSS For Absolute Beginners" className="w-full" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">Tailwind CSS For Absolute Beginners</div>
              <p className="text-grey-700 text-base">
                Tailwind is a utility-first CSS framework. In contrast to other CSS frameworks like Bootstrap or Materialize CSS it doesn’t come with predefined components. Instead Tailwind CSS operates on a lower level and provides you with a set of CSS helper classes. By using this classes you can rapidly create custom design with ease. Tailwind CSS is not opinionated and let’s you create you own unique design.
              </p>
            </div>
            <div className="px-6 py-4">
              <span className="tag">#tailwindcss</span>
              <span className="tag">#css</span>
              <span className="tag">#webdevelopment</span>
            </div>
          </div>
        </div>
      )
    }
  }

export default ProduceListCardItem